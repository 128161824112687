<template lang="pug">
.row.new-feed-row
  .col-3.feed-domain
    om-select#new-feed-domain(
      v-model="selectedDomain"
      :options="domainOptions"
      searchable
      :placeholder="$t('feeds.newFeed.domain.placeholder')"
      :error="$v.selectedDomain.$error"
    )
  .col-2.feed-type
    om-select#new-feed-type(
      v-model="selectedType"
      :options="typeOptions"
      searchable
      :placeholder="$t('feeds.newFeed.type.placeholder')"
      :error="$v.selectedType.$error"
    )
  .col-6.feed-url
    om-input#new-feed-url(
      v-model="url"
      :placeholder="$t('feeds.newFeed.url.placeholder')"
      :error="$v.url.$error"
    )
      template(slot="error" v-if="$v.url.$error && !$v.url.isCool")
        span {{ $t('feeds.newFeed.url.alert') }}
  .col-1.feed-button.d-flex.justify-content-end
    div
      om-button#new-feed-new(primary @click="addNewFeed" :loading="saving") {{ $t('feeds.newFeed.actionButton') }}
</template>
<script>
  import { mapGetters } from 'vuex';
  import { validateUrl } from '@/util';
  import { required } from 'vuelidate/lib/validators';
  import ADD_PRODUCT_FEED from '@/graphql/AddProductFeed.gql';

  export default {
    data: () => ({
      selectedDomain: null,
      selectedType: { key: 'GoogleProductFeed' },
      url: '',
      saving: false,
      typeOptions: [
        { key: 'GoogleProductFeed', value: 'Google Product Feed' },
        { key: 'ArukeresoProductFeed', value: 'Árukereső Product Feed' },
      ],
    }),
    computed: {
      ...mapGetters(['domains']),
      domainOptions() {
        return this.domains
          .map((domain) => ({
            key: domain._id,
            value: domain.domain,
          }))
          .filter(({ domain }) => domain !== '*');
      },
    },
    methods: {
      async addNewFeed() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }

        this.saving = true;

        try {
          await this.$apollo.mutate({
            mutation: ADD_PRODUCT_FEED,
            variables: {
              type: this.selectedType.key,
              feed: {
                domainId: this.selectedDomain.key,
                url: this.url,
              },
            },
          });

          this.reset();
          this.$notify({
            type: 'success',
            text: this.$t('feeds.newFeed.success'),
          });
        } catch (err) {
          this.$notify({
            type: 'error',
            text: this.$t('feeds.newFeed.failed'),
          });
          console.error('Caugth error during add feed', { msg: err.message, stack: err.stack });
        } finally {
          this.saving = false;
          this.$v.$reset();
          this.$bus.$emit('reloadFeedTable');
        }
      },
      reset() {
        this.selectedDomain = null;
        this.url = '';
      },
    },
    validations: {
      selectedDomain: {
        required,
      },
      selectedType: {
        required,
      },
      url: {
        isCool(val) {
          return validateUrl(val);
        },
      },
    },
  };
</script>
